<template>
	<div class="EquipPerformanceSearchBox">
		<div class="row p-10" :style="{ justifyContent: 'start' }">
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<!-- <div class="pt-2 p-l-5">
					<span>구역</span>
				</div>
				<div class="p-l-5 p-r-5">
					<tree-select
						:style="{ width: '120px', fontSize: '13px', fontWidht: 'normal' }"
						:load-options="loadOptions"
						v-model="searchCond.zoneIdx"
						:multiple="false"
						:options="searchZoneList"
						:clear-on-select="false"
						@input="setEquipList"
					/>
				</div> -->
				<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
					<div class="pt-2 p-l-5">
						<span>{{$t('설비 계통')}}</span>
					</div>
					<div class="p-l-5 p-r-5">
						<b-form-select v-model="searchCond.systemCode" :options="searchSystemCodeList" @input="selectSystemCode"></b-form-select>
					</div>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t('설비 기기')}}</span>
				</div>
				<div class="p-l-5 p-r-5" v-if="searchCond.systemCode === null">
					<b-form-select disabled></b-form-select>
				</div>
				<div class="p-l-5 p-r-5" v-else>
					<b-form-select v-model="searchCond.equipIdx" :options="searchEquipList" @input="selectEquip"></b-form-select>
				</div>
			</div>

			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t('조회유형')}}</span>
				</div>
				<div class="p-l-5 p-r-5">
					<b-button-group>
						<b-button v-for="(btn, i) in buttons" :key="`button${i}`" class="btn btn-white" :pressed.sync="btn.state" @click="onTimeButton(btn)">
							{{ btn.caption }}
						</b-button>
					</b-button-group>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t('조회기간')}}</span>
				</div>
				<div class="p-l-5 d-flex flex-row">
					<div class="d-flex flex-row">
						<div>
							<date-picker
								ref="fromDate"
								:value="searchCond.fromDate"
								:config="{ format: searchCond.fromFormat, viewMode: searchCond.timeDsvn, locale: 'ko' }"
								@dp-hide="onFromDateSelect($event)"
							></date-picker>
						</div>
					</div>
					<div class="pt-2 p-l-5 p-r-5">
						<span>~</span>
					</div>
					<div class="p-r-5">
						<date-picker ref="toDate" :value="searchCond.toDate" :config="{ format: searchCond.toFormat, viewMode: searchCond.timeDsvn, locale: 'ko' }" disabled></date-picker>
					</div>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div
					class="d-flex"
					:style="{ padding: '5px', height: '34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems: 'center' }"
					style="border:2px solid #348fe2; border-radius: 10px!important;"
				>
					<b-button squared class="fa-3x" variant="#ffffff" size="md" @click="onSearch()" :style="{ width: '35px', height: '100%', padding: 0, color: '#348fe2' }">
						<span>{{ $t("조회") }}</span>
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";
	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		name: "EquipPerformanceSearchBox",
		props: ["systemTypeCode"],
		data() {
			return {
				searchCond: {
					// zoneIdx: null,
					// systemCode: null,
					// systemTypeCode: null,
					// systemTypeCode: 'Env',
					// systemTypeCode: null,
					// equipTypeCode: "EHPI",
					equipIdx: null,
					dvsnCode: null,
					timeDsvn: "days",
					fromFormat: "YYYY-MM-DD 00:00",
					toFormat: "YYYY-MM-DD 23:59",
					fromDate: new Date(),
					toDate: new Date(),
					timeForm: null,
				},
				searchSystemCodeList: null,
				// searchZoneList: null,
				searchEquipList: null,
				buttons: [
					{ caption: global.xe.$t("일간"), state: true, value: "Hour" },
					{ caption: global.xe.$t("월간"), state: false, value: "Day" },
					{ caption: global.xe.$t("연간"), state: false, value: "Month" },
				],
			};
		},
		async created() {
			await this.setDefaultValue();
			this.defaultSearch();
		},
		methods: {
			defaultSearch() {
				this.searchCond.systemCode = this.systemTypeCode;

        console.log(this.systemTypeCode);

				let timeDsvn = "";
				let timeForm;
				let fromDate;
				let toDate;

				if (this.searchCond.timeDsvn === "days") {
					fromDate = this.searchCond.fromDate.format("yyyy-MM-dd 00:00");
					toDate = this.searchCond.toDate.format("yyyy-MM-dd 23:59");

					timeDsvn = "Hour";
					timeForm = fromDate.split(" ")[0];
				} else if (this.searchCond.timeDsvn === "months") {
					console.log(this.searchCond.fromDate);
					let month = this.searchCond.fromDate.getMonth() + 1;
					let lastDay = new Date(this.searchCond.fromDate.getFullYear(), month, 0).getDate();

					this.searchCond.fromFormat = "YYYY-MM-01";
					this.searchCond.toFormat = `YYYY-MM-${lastDay}`;

					fromDate = this.searchCond.fromDate.format("yyyy-MM-dd 00:00");
					toDate = this.searchCond.toDate.format("yyyy-MM-dd 23:59");

					timeDsvn = "Day";
					let splitString = fromDate.split("-");
					timeForm = splitString[0] + "-" + splitString[1];
				} else if (this.searchCond.timeDsvn === "years") {
					this.searchCond.fromFormat = "YYYY-01";
					this.searchCond.toFormat = "YYYY-12";

					fromDate = this.searchCond.fromDate.format("yyyy-MM-dd 00:00");
					toDate = this.searchCond.toDate.format("yyyy-MM-dd 23:59");

					timeDsvn = "Month";
					let splitString = fromDate.split("-");
					timeForm = splitString[0];
				}

				let param = {
					timeDsvn: timeDsvn,
					fromDate: fromDate,
					toDate: toDate,
					dvsnCode: this.searchCond.dvsnCode,
					equipTypeCode: this.searchCond.equipTypeCode,
					equipIdx: this.searchCond.equipIdx,
					systemCode: this.searchCond.systemCode,
					// systemTypeCode: this.searchCond.systemTypeCode,
					timeForm: timeForm,
				};

				this.$emit("defaultSearch", param);
			},
			async setDefaultValue() {
				this.searchCond.systemTypeCode = this.systemTypeCode;

				this.searchSystemCodeList = await this.getSystemCodeList();
				// this.searchCond.systemCode = this.searchSystemCodeList[0].value;
        this.searchCond.systemCode = this.systemTypeCode ?? this.searchSystemCodeList[0].value;

				this.searchEquipList = await this.getEquipListBySystem();
				// this.searchZoneList = await this.getZoneListHierarchi();
				// this.searchCond.zoneIdx = this.searchZoneList[0].zoneIdx;
				// this.searchEquipList = await this.getEquipListZone();
				this.searchCond.equipIdx = this.searchEquipList[0].value;
				this.searchCond.dvsnCode = this.searchEquipList[0].data.dvsnCode;

				// this.searchCond.timeDsvn = "days";
				// this.searchCond.fromFormat = "YYYY-MM-DD 00:00";
				// this.searchCond.toFormat = "YYYY-MM-DD 23:59";
				// this.searchCond.fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
				// this.searchCond.toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			},
			async setEquipList() {
				this.searchEquipList = await this.getEquipListZone();
			},
			async getSystemCodeList() {
				this.searchSystemCodeList = null;
				this.searchCond.systemCode = null;

				let result = await backEndApi.systeminfo.getSystemInfoList(null, null, this.searchCond.systemTypeCode);

				if (!result.data.errno) {
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						options.push({
							value: result.data[i].systemCode,
							text: result.data[i].systemName,
							data: result.data[i],
						});
					}

					return options;
				} else {
					this.alertNoti(result.data.message);
					this.searchCond.systemCode = null;
				}
			},

			async getEquipListBySystem() {
				if (this.searchCond.systemCode === undefined) {
					this.searchCond.systemCode = null;
					return;
				}

				this.searchEquipList = null;
				this.searchCond.equipIdx = null;

				try {
					let result = await backEndApi.equip.getEquipListBySystem(this.searchCond.systemCode);

					if (!result.data.errno) {
						let options = [];
						options.push({ value: null, text: global.xe.$t("전체"), data: { dvsnCode: "eqp" } });
						for (let i = 0; i < result.data.length; i++) {
							if (result.data[i].dvsnCode !== "grp") {
								options.push({
									value: result.data[i].equipIdx,
									text: result.data[i].equipName,
									data: result.data[i],
								});
							}
						}

						return options;
					} else {
						this.alertNoti(result.data.message);
						this.searchCond.zoneIdx = null;
					}
				} catch (e) {
					new AppErrorHandler(e)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR, popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.errHandling();
					return;
				}
			},
			async selectSystemCode(e) {
				if (this.searchSystemCodeList !== null) {
					for (let i = 0; i < this.searchSystemCodeList.length; i++) {
						if (this.searchSystemCodeList[i].data.systemCode === e) {
							this.searchEquipList = await this.getEquipListBySystem();

							this.searchCond.equipIdx = this.searchEquipList[0].value;
							this.searchCond.dvsnCode = this.searchEquipList[0].data.dvsnCode;
							break;
						}
					}
				}
			},
			async selectEquip(e) {
				if (this.searchEquipList !== null) {
					for (let i = 0; i < this.searchEquipList.length; i++) {
						if (this.searchEquipList[i].data.equipIdx === e) {
							this.searchCond.dvsnCode = this.searchEquipList[i].data.dvsnCode;
							break;
						}
					}
				}
			},
			onTimeButton(btn) {
				this.buttons.forEach((item) => {
					item.state = false;
				});
				btn.state = true;

				let now = new Date();

				if (btn.value === "Hour") {
					this.searchCond.timeDsvn = "days";

					this.searchCond.fromFormat = "YYYY-MM-DD 00:00";
					this.searchCond.toFormat = "YYYY-MM-DD 23:59";

					this.searchCond.fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
					this.searchCond.toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
				} else if (btn.value === "Day") {
					this.searchCond.timeDsvn = "months";

					let month = now.getMonth() + 1;
					let lastDay = new Date(now.getFullYear(), month, 0).getDate();

					this.searchCond.fromFormat = "YYYY-MM-01";
					this.searchCond.toFormat = `YYYY-MM-${lastDay}`;

					this.searchCond.fromDate = new Date(now.getFullYear(), month -1, 1);
					this.searchCond.toDate = new Date(now.getFullYear(), month, 0);
				} else if (btn.value === "Month") {
					this.searchCond.timeDsvn = "years";

					this.searchCond.fromFormat = "YYYY-01";
					this.searchCond.toFormat = "YYYY-12";

					this.searchCond.fromDate = new Date(now.getFullYear(), 0, 1);
					this.searchCond.toDate = new Date(now.getFullYear(), 12, 0);
				}
			},
			onFromDateSelect(e) {
				let inputDate = new Date(e.target.value);
				this.searchCond.fromDate = inputDate;

				if (this.searchCond.timeDsvn === "days") {
					this.searchCond.toDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

					// this.searchCond.toDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
				} else if (this.searchCond.timeDsvn === "months") {
					let month = inputDate.getMonth() + 1;

					let lastDay = new Date(inputDate.getFullYear(), month, 0).getDate();

					this.searchCond.toFormat = `YYYY-MM-${lastDay}`;
					this.searchCond.toDate = new Date(inputDate.getFullYear(), month, 0);

					// this.searchCond.toDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), lastDay);
				} else if (this.searchCond.timeDsvn === "years") {
					this.searchCond.toDate = new Date(inputDate.getFullYear(), 12, 0);

					// this.searchCond.toDate = new Date(inputDate.getFullYear(), 12, 0);
				}
			},
			onSearch() {
				// if (this.searchCond.equipIdx === null) {
				// 	this.alertNoti("설비 기기를 선택해주세요.");
				// 	return;
				// }

				let fromDate = this.$refs.fromDate.$el.value;
				let toDate = this.$refs.toDate.$el.value;

				let timeDsvn = "";
				let timeForm;

				if (this.searchCond.timeDsvn === "days") {
					timeDsvn = "Hour";
					timeForm = fromDate.split(" ")[0];
				} else if (this.searchCond.timeDsvn === "months") {
					timeDsvn = "Day";
					let splitString = fromDate.split("-");
					timeForm = splitString[0] + "-" + splitString[1];
				} else if (this.searchCond.timeDsvn === "years") {
					timeDsvn = "Month";
					let splitString = fromDate.split("-");
					timeForm = splitString[0];
				}

				let param = {
					timeDsvn: timeDsvn,
					fromDate: fromDate,
					toDate: toDate,
					dvsnCode: this.searchCond.dvsnCode,
					equipTypeCode: this.searchCond.equipTypeCode,
					systemCode: this.searchCond.systemCode,
					equipIdx: this.searchCond.equipIdx,
					timeForm: timeForm,
				};

				this.$emit("search", param);
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

<style lang="scss">
	.EquipPerformanceSearchBox {
		.SearchLabel {
			align-self: center;
			text-align: center;
			font-size: 15px;
			font-weight: bold;
		}
	}
</style>
