<template>
	<div class="EquipPerformance col-md-12 default">
		<div class="col-xl-12">
			<header-box :title="`${$t('건물 운영 관리2')} > ${title}`" />

			<div class="searchArea row">
				<div class="col-md-12 innerBox">
					<equip-performance-search-box ref="equipPerformanceSearch" :systemTypeCode="systemTypeCode" @search="onSearch" @defaultSearch="defaultSearch"></equip-performance-search-box>
					<!-- <search-box ref="searchBox" v-if="searchBoxCond" :options="searchBoxCond" @submit:data="onSearchComplete" -->
					<!-- @search:data="onSearch" @initSetting="initSetting" > </search-box> -->
				</div>
			</div>

			<div class="col-12">
				<equip-performance-chart v-if="performerData !== null" :dataList="performerData.dataList" :propList="performerData.propList"></equip-performance-chart>
			</div>

			<div class="col-12">
				<equip-performance-grid-box
					v-if="performerData !== null"
					:dataList="performerData.dataList"
					:propList="performerData.propList"
					:searchCond="searchCond"
					:timeDsvn="timeDsvn"
					:excelTitle="title"
				></equip-performance-grid-box>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";
	import headerBox from "@views/component/headerBox/analysis/Header.vue";

	import equipPerformanceSearchBox from "./EquipPerformanceSearchBox.vue";
	import equipPerformanceChart from "./EquipPerformanceChart.vue";
	import equipPerformanceGridBox from "./EquipPerformanceGridBox.vue";

	export default {
		props: ["systemTypeCode", "name"],
		data() {
			return {
				performerData: null,
				searchCond: null,
				timeDsvn: null,
			};
		},
		computed: {
			title() {
				console.log(this.name);
				let result = "";
				if (this.name === "EquipPerformance") {
					result = `${global.xe.$t("EHP 운전현황 분석")}`;
				} else if (this.name === "IndoorEnviron") {
					result = `${global.xe.$t("실내환경정보")}`;
				}

				return result;
			},
		},
		components: {
			equipPerformanceSearchBox,
			equipPerformanceChart,
			equipPerformanceGridBox,
			headerBox,
		},
		mounted() {
			console.log(this.systemTypeCode);
		},
		methods: {
			defaultSearch(param) {
				this.onSearch(param);
			},
			async onSearch(param) {
				let timeDsvn;
				this.timeDsvn = param.timeDsvn;

				console.log(param);

				if (param.timeDsvn === "Hour") {
					timeDsvn = "일간";
				} else if (param.timeDsvn === "Day") {
					timeDsvn = "월간";
				} else if (param.timeDsvn === "Month") {
					timeDsvn = "연간";
				}

				this.searchCond = {
					timeForm: param.timeForm,
					timeDsvn: timeDsvn,
				};

				let result = await backEndApi.equip.systemPerform(param);

				if (!result.data.errno) {
					// unitSmallCodeName : null to '-'
					result.data.propList.forEach((prop) => {
						prop.unitSmallCodeName ??= "-";
					});
					this.performerData = result.data;
				} else {
					this.alertNoti(result.data.message);
				}
			},
		},
	};
</script>

<style>
	.el-table .kbet-el-table th {
		background-color: #f5f7fa;
	}
	.el-table th,
	.el-table tr {
		background-color: #f5f7fa;
	}

	.comparisonPage {
		background-color: brown;
	}
	.searchArea {
		margin: 8px 0 8px 0;
		background-color: #00000010;
		border-radius: 8px;
	}
	.cardArea,
	.chartArea,
	.graphArea,
	.gridArea {
		margin: 8px 0 8px 0;
	}
	.innerBox {
		padding: 5px;
	}

	.dateSelect {
		background-color: cornflowerblue;
		height: 200px;
	}
	.timeSelect {
		background-color: cyan;
		height: 200px;
	}
	.cardView {
		background-color: darkkhaki;
		height: 200px;
	}
	.mixChart {
		background-color: #ffffff;
		height: 300px;
	}
	.drawLine {
		height: 300px;
	}
</style>
